import React from 'react';
import { AppProps } from 'next/app';
import '../styles/index.css';
import { DocumentContextProvider } from '../context/DocumentContext';
import '../../config/sentry';
import Firebase, { FirebaseContext } from '../components/Firebase';

const App = ({ Component, pageProps }: AppProps): React.ReactElement => (
    <FirebaseContext.Provider value={new Firebase() as any}>
        <DocumentContextProvider>
            <Component {...pageProps} />
        </DocumentContextProvider>
    </FirebaseContext.Provider>
);

export default App;
