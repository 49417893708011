import * as React from 'react';
import { IApplicationState } from '../components/DocumentSelection/DocumentSelectionHelpers';
import { DocumentActionTypes, documentReducer } from './DocumentReducer';
import DocumentContextHelpers from './DocumentContextHelpers';

const loadedContext = DocumentContextHelpers.loadDocumentsContextFromLocalStorage();

export const DocumentContext = React.createContext<{
	state: IApplicationState;
	dispatch: React.Dispatch<DocumentActionTypes>;
}>({
	state: loadedContext,
	dispatch: () => null,
});

export const mainReducer = ({ documentsContext }, action) => ({
	documentsContext: documentReducer(documentsContext, action),
});

type Props = {
	children?: React.ReactNode;
};
export const DocumentContextProvider: React.FC = ({ children }: Props) => {
	const [state, dispatch] = React.useReducer(mainReducer, loadedContext);

	return <DocumentContext.Provider value={{ state, dispatch }}>{children}</DocumentContext.Provider>;
};
