import DocumentContextHelpers, { ImageObjectType } from './DocumentContextHelpers';
import { IDocumentsUploadStatus } from '../components/DocumentSelection/DocumentSelectionHelpers';

export enum Types {
	EraseContext = 'ERASE_CONTEXT',
	ResetCurrentDocument = 'RESET_CURRENT_DOCUMENT',
	SetCurrentDocument = 'SET_CURRENT_DOCUMENT',
	SetEkycUuid = 'SET_EKYC_UUID',
	SetDocumentImage = 'SET_DOCUMENT_IMAGE',
	DiscardDocumentImage = 'DISCARD_DOCUMENT_IMAGE',
	UploadDocumentImage = 'UPLOAD_DOCUMENT_IMAGE',
	UpdateUploadProgress = 'UPLOAD_PROGRESS',
	UpdateUploadStatus = 'UPDATE_STATUS',
}

interface EraseContextAction {
	type: Types.EraseContext;
}

interface ResetCurrentDocumentAction {
	type: Types.ResetCurrentDocument;
}

interface SetCurrentDocumentAction {
	type: Types.SetCurrentDocument;
	payload: {
		currentDocument: string;
	};
}

interface SetEkycUuidAction {
	type: Types.SetEkycUuid;
	payload: {
		ekycUuid: string;
		documentGroup?: string;
	};
}

interface SetDocumentImageAction {
	type: Types.SetDocumentImage;
	payload: {
		image: ImageObjectType;
	};
}

interface DiscardDocumentImageAction {
	type: Types.DiscardDocumentImage;
	payload: {
		imageType: string;
		redirect: () => void;
	};
}

interface UpdateUploadProgressAction {
	type: Types.UpdateUploadProgress;
	payload: {
		documentId: string;
		uploadProgressPercentage: number;
	};
}

export type ImageMetadataType = { status?: string; direction?: string } | undefined;
interface UploadDocumentImageAction {
	type: Types.UploadDocumentImage;
	payload: {
		dispatchAsync: React.Dispatch<DocumentActionTypes>;
		documentId: string;
		imageType: string;
		metadata?: ImageMetadataType;
	};
}

interface UpdateUploadStatusAction {
	type: Types.UpdateUploadStatus;
	payload: {
		documentId: string;
		imageType: string;
		uploadStatus: string;
	};
}

export type DocumentActionTypes =
	| EraseContextAction
	| ResetCurrentDocumentAction
	| SetCurrentDocumentAction
	| SetEkycUuidAction
	| SetDocumentImageAction
	| DiscardDocumentImageAction
	| UpdateUploadProgressAction
	| UploadDocumentImageAction
	| UpdateUploadStatusAction;

export const documentReducer = (currentDocumentState: IDocumentsUploadStatus, action: DocumentActionTypes) => {
	switch (action.type) {
		case Types.EraseContext:
			// console.log('erase');
			return DocumentContextHelpers.eraseContext(/* currentDocumentState */);
		case Types.ResetCurrentDocument:
			// console.log('reset');
			return DocumentContextHelpers.resetCurrentDocument(currentDocumentState);
		case Types.SetCurrentDocument:
			// console.log('set');
			return DocumentContextHelpers.setCurrentDocument(currentDocumentState, action?.payload?.currentDocument);
		case Types.SetEkycUuid:
			// console.log('set ekyc uuid');
			return DocumentContextHelpers.setEkycUuid(currentDocumentState, action?.payload?.ekycUuid, action?.payload?.documentGroup);
		case Types.SetDocumentImage:
			// console.log('save');
			return DocumentContextHelpers.setDocumentImage(currentDocumentState, action?.payload?.image);
		case Types.DiscardDocumentImage:
			// console.log('discard');
			return DocumentContextHelpers.discardDocumentImage(currentDocumentState, action?.payload?.imageType, action?.payload?.redirect);
		case Types.UpdateUploadProgress:
			return DocumentContextHelpers.updateUploadProgress(
				currentDocumentState,
				action?.payload?.documentId,
				action?.payload?.uploadProgressPercentage,
			);
		case Types.UploadDocumentImage:
			// console.log('upload', action?.payload);
			return DocumentContextHelpers.uploadDocumentImages(
				action?.payload?.dispatchAsync,
				currentDocumentState,
				action?.payload?.documentId,
				action?.payload?.imageType,
				action?.payload?.metadata,
			);
		case Types.UpdateUploadStatus:
			// console.log('temp update status');
			return DocumentContextHelpers.updateUploadStatus(
				currentDocumentState,
				action?.payload?.documentId,
				action?.payload?.imageType,
				action?.payload?.uploadStatus as any,
			);
		// case Types.Delete:
		// 	return [...currentDocumentState.filter(product => product.id !== action.payload.id)];
		default:
			return currentDocumentState;
	}
};
