type IUploadStatus = 'none' | 'in_progress' | 'completed' | 'error';

export interface IApplicationState {
	documentsContext: IDocumentsUploadStatus;
}

type DocumentDataType = {
	uploadStatus: IUploadStatus;
	uploadProgressPercentage: number;
	images?: Record<string, unknown>;
};

export interface IDocumentsUploadStatus {
	currentDocument?: string;
	ekycUuid?: string;
	documentGroup?: string;
	documents?: {
		driver_licence?: DocumentDataType;
		passport?: DocumentDataType;
		liveness?: DocumentDataType;
		national_id_card?: DocumentDataType;
		student_id_card?: DocumentDataType;
		change_of_name?: DocumentDataType;
		medicare?: DocumentDataType;
		birth_certificate?: DocumentDataType;
	};
}

export enum UPLOAD_STATUS {
	NONE = 'none',
	IN_PROGRESS = 'in_progress',
	COMPLETED = 'completed',
	ERROR = 'error',
}
export enum VIEWFINDER_FORMAT {
	NONE = 'none',
	PASSPORT = 'passport',
	CARD = 'card',
	A4 = 'a4',
}

export enum IMAGE_TYPES {
	FRONT = 'front',
	BACK = 'back',
}

const DOCUMENT_LIST: any = {
	passport: {
		id: 'passport',
		text: 'Passport',
		icon: null,
		viewfinderFormat: VIEWFINDER_FORMAT.PASSPORT,
		requiredImages: [IMAGE_TYPES.FRONT],
	},
	driver_licence: {
		id: 'driver_licence',
		text: 'Driver licence',
		icon: null,
		viewfinderFormat: VIEWFINDER_FORMAT.CARD,
		requiredImages: [IMAGE_TYPES.FRONT, IMAGE_TYPES.BACK],
	},
	national_id_card: {
		id: 'national_id_card',
		text: 'ID card',
		icon: null,
		viewfinderFormat: VIEWFINDER_FORMAT.CARD,
		requiredImages: [IMAGE_TYPES.FRONT, IMAGE_TYPES.BACK],
	},
	student_id_card: {
		id: 'student_id_card',
		text: 'Student ID',
		icon: null,
		viewfinderFormat: VIEWFINDER_FORMAT.CARD,
		requiredImages: [IMAGE_TYPES.FRONT, IMAGE_TYPES.BACK],
	},
	medicare: {
		id: 'medicare',
		text: 'Medicare',
		icon: null,
		viewfinderFormat: VIEWFINDER_FORMAT.CARD,
		requiredImages: [IMAGE_TYPES.FRONT],
	},
	utility_bill: {
		id: 'utility_bill',
		text: 'Utility bill',
		icon: null,
		viewfinderFormat: VIEWFINDER_FORMAT.NONE,
		requiredImages: [IMAGE_TYPES.FRONT],
	},
	bank_statement: {
		id: 'bank_statement',
		text: 'Bank statement',
		icon: null,
		viewfinderFormat: VIEWFINDER_FORMAT.NONE,
		requiredImages: [IMAGE_TYPES.FRONT],
	},
	// change_of_name: {
	// 	id: 'change_of_name',
	// 	text: 'Change of Name',
	// 	icon: null,
	// 	viewfinderFormat: VIEWFINDER_FORMAT.A4,
	// 	requiredImages: [IMAGE_TYPES.FRONT],
	// },
	// birth_certificate: {
	// 	id: 'birth_certificate',
	// 	text: 'Birth Certificate',
	// 	icon: null,
	// 	viewfinderFormat: VIEWFINDER_FORMAT.A4,
	// 	requiredImages: [IMAGE_TYPES.FRONT],
	// },
};

const getDocumentGroup = (type = 'default') => {
	switch (type) {
		case 'student': {
			return [
				'passport',
				'driver_licence',
				'student_id_card', // <<< different from default
				'medicare',
				'utility_bill',
				'bank_statement',
			];
		}
		default: {
			return [
				'passport',
				'driver_licence',
				'national_id_card', // different from student
				'medicare',
				'utility_bill',
				'bank_statement',
			];
		}
	}
};

const getDocuments = (documentsContext: IDocumentsUploadStatus = {}) => {
	const documentGroup = documentsContext?.documentGroup;
	// return documentList with uploadStatus included
	return getDocumentGroup(documentGroup).map(documentKey => {
		const uploadStatus = documentsContext[documentKey]?.uploadStatus ?? UPLOAD_STATUS.NONE;
		return { ...DOCUMENT_LIST[documentKey], uploadStatus };
	});
};

const requiredDocumentsSupplied = (requiredDocuments: string[], suppliedDocuments: string[]): boolean =>
	requiredDocuments.every(documentId => suppliedDocuments.includes(documentId));

const getUploadedDocuments = documentContext => {
	let uploadedDocuments: string[] = [];
	Object.keys(DOCUMENT_LIST).forEach(documentId => {
		if (documentContext?.[documentId]?.uploadStatus === UPLOAD_STATUS.COMPLETED) {
			uploadedDocuments = [...uploadedDocuments, documentId];
		}
	});
	return uploadedDocuments;
};

const checkCollectionCriteria = (uploadedDocuments: string[], documentGroup = 'default') => {
	let activeGroups: string[][] = [];

	switch (documentGroup) {
		case 'student': {
			// pp with student ID
			const group1 = ['passport', 'student_id_card'];

			activeGroups = [group1];
			break;
		}
		default: {
			// pp with anything
			const group1 = ['passport', 'driver_licence'];
			const group2 = ['passport', 'national_id_card'];
			const group3 = ['passport', 'medicare'];
			const group4 = ['passport', 'utility_bill'];
			const group5 = ['passport', 'bank_statement'];
			// dl with anything
			const group6 = ['driver_licence', 'national_id_card'];
			const group7 = ['driver_licence', 'medicare'];
			const group8 = ['driver_licence', 'utility_bill'];
			const group9 = ['driver_licence', 'bank_statement'];
			// id with anything
			const group10 = ['national_id_card', 'medicare'];
			const group11 = ['national_id_card', 'utility_bill'];
			const group12 = ['national_id_card', 'bank_statement'];

			activeGroups = [group1, group2, group3, group4, group5, group6, group7, group8, group9, group10, group11, group12];
			break;
		}
	}

	return activeGroups.some(activeGroup => requiredDocumentsSupplied(activeGroup, uploadedDocuments));
};

const checkEnoughDocumentCollected = (state: IApplicationState): boolean => {
	const documents = state?.documentsContext?.documents;
	const documentGroup = state?.documentsContext?.documentGroup;
	const uploadedDocuments = getUploadedDocuments(documents);
	return checkCollectionCriteria(uploadedDocuments, documentGroup);
};

export default {
	getDocuments,
	UPLOAD_STATUS,
	DOCUMENT_LIST,
	checkEnoughDocumentCollected,
};
