import FirebaseContext, { withFirebase } from './context';
import Firebase from './firebase';

export default Firebase;

export type IFirestore = {
	// firestore: firebase.firestore.Firestore;
	// auth: firebase.auth.Auth;
	// db: firebase.database.Database;
	// functions: firebase.functions.Functions;
	// doSignOut: Function;
	// doCreateUserWithAnonymous: Function;
	// callRequestEkycAccess: Function;
	// uploadImage: Function;
	// uploadVideo: Function;
	// user: firebase.database.Reference;
	// users: firebase.database.Reference;
	// incomingQueueWrite: Function;
	// incomingQueueRead: Function;
};

export { FirebaseContext, withFirebase };
