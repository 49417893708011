import * as Sentry from '@sentry/react';

if (!process.env.SENTRY_DSN || process.env.NODE_ENV === 'test') {
	console.log('Sentry is disabled.');
}

Sentry.init({
	dsn: process.env.SENTRY_DSN,
	enabled: process.env.NODE_ENV !== 'test',
	environment: process.env.APP_STAGE,
	release: process.env.APP_VERSION_RELEASE,
});
